import { css } from '@emotion/react'
import { ensureError, UnreachableCaseError } from '@orus.eu/error'
import {
  ButtonLink,
  colorTokens,
  spacing,
  Text,
  TextContainer,
  useCrash,
  useTranslate,
  useUiContext,
} from '@orus.eu/pharaoh'
import { isFailure, isSuccess } from '@orus.eu/result'
import { useSearch } from '@tanstack/react-router'
import { memo, useEffect, useState } from 'react'
import { trpc } from '../../client'
import { GlobalLoadingState } from '../molecules/global-loading-state'
import { InvalidLinkMessage } from '../organisms/invalid-link-message'
import { ActionPageLayout } from '../templates/action-page-layout'

export default memo(function UnsubscribePage() {
  const translate = useTranslate()
  const [status, setStatus] = useState<'processing' | 'invalid-link' | 'done'>('processing')
  const crash = useCrash()
  const searchParams = useSearch({ strict: false })
  const screenType = useUiContext()

  const token = searchParams.token

  useEffect(() => {
    if (!token) {
      setStatus('invalid-link')
      return
    }

    let cancelled = false

    disableNotification(token).then(
      (result) => {
        if (cancelled) return

        if (isSuccess(result)) {
          setStatus('done')
          return
        }

        switch (result.problem) {
          case 'invalid-token':
            setStatus('invalid-link')
            break
          default:
            throw new UnreachableCaseError(result.problem)
        }
      },
      (err) => {
        if (cancelled) return
        crash(ensureError(err))
      },
    )

    return () => {
      cancelled = true
    }
  }, [crash, token])

  switch (status) {
    case 'processing':
      return <GlobalLoadingState />
    case 'invalid-link':
      return <InvalidLinkMessage />
    case 'done':
      return (
        <ActionPageLayout
          maxWidth="sm"
          body={
            <>
              <Text variant="h1">{translate('invoice_email_unsubscribe_page_title')}</Text>

              <TextContainer
                css={css`
                  margin-top: ${spacing[30]};
                `}
                variant="body2"
                color={colorTokens['color-text-base-basic']}
              >
                <Text>{translate('invoice_email_unsubscribe_page_description')}</Text>
                <Text>{translate('invoice_email_unsubscribe_page_resubscribe_info')}</Text>
              </TextContainer>
            </>
          }
          actions={
            <ButtonLink
              fullWidth={screenType === 'mobile'}
              variant="primary"
              size={screenType === 'mobile' ? 'large' : 'medium'}
              to="/invoices"
            >
              {translate('invoice_email_unsubscribe_page_go_to_invoices')}
            </ButtonLink>
          }
        />
      )
  }
})

async function disableNotification(token: string) {
  const configurationResult =
    await trpc.notificationsConfiguration.getMyNotificationConfigurationWithoutConnection.query(token)

  if (isFailure(configurationResult)) return configurationResult

  return trpc.notificationsConfiguration.updateMyNotificationConfigurationWithoutConnection.mutate({
    token,
    notificationsConfiguration: { ...configurationResult.output, sendInvoiceEmail: false },
  })
}
